import React from 'react'
import LayoutBs from '../../components/LayoutBs'
import Header from '../../components/Header/header'
import Footer from '../../components/Footer/footer'
import "animate.css/animate.min.css";
import {impresum} from '../../components/strings'
import AOS from 'aos';

 class ImpresumPageBs extends React.Component {
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  render() {
    const prefix_string = "bs";
    return (
      <LayoutBs>
      <Header prefix_string_page={prefix_string}/>
          <div data-aos='fade-zoom-in'>
          
            <section id="p_consulting" className="section pages">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
            <div className="container">        
       
                <h2 className="title">{impresum}</h2>

                <div className="content">
                <h6>WELL BUSINESS PROMOTION d.o.o.</h6>
                <h6>ADRESA:</h6>
                <p className="padding-top-small">Sarajevo City Center <br />
                Safeta Mujića 2, 
                71000 Sarajevo, 
                Bosnia and Herzegovina</p>

                <h6>WEB STRANICA:</h6><p className="padding-top-small"> wellpromotion.ba</p>
                <h6>E-MAIL:</h6> <p className="padding-top-small">help@wellpromotion.ba</p>
                <h6>TELEFON:</h6><p className="padding-top-small"> +387 33 831 428 (Viber / WhatsApp)</p>

                <h6>REGISTRACIJSKI BROJ KOMPANIJE:</h6>
                <p className="padding-top-small"> ID 4202401300003 <br />PDV 202401300003</p>

                <h6>REGISTROVANO KOD SUDA:</h6>
                <p className="padding-top-small">  Općinski sud u Sarajevu, MBS: 65-01-0584-17</p>

                </div>
                </div>
             

                 
        </section>
        </div>
          <Footer prefix_string_page={prefix_string}/>
      </LayoutBs>
    )
  }
}


export default ImpresumPageBs